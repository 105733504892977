<i18n src="@/locales/traditional_implant_risk.json" />

<template>
  <div class="trad-implant-risk">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanArticle>
      <b-message
        type="is-info"
      >
        <p>
          {{ $t('message_1') }}
        </p>
      </b-message>
    </ImanArticle>
    <ImanArticle>
      <b-message
        type="is-info"
      >
        <p>
          {{ $t('message_2') }}
        </p>
      </b-message>
    </ImanArticle>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          {{ $t('article_p1') }}
        </p>
      </ImanHomeSection>
    </section>
    <section class="section">
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_2') }}
        </h3>
        <p>
          {{ $t('article_p2') }}
        </p>
      </ImanArticle>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_3') }}</ImanH3>
        <p>
          {{ $t('article_p3_1') }}
        </p>
        <p>
          {{ $t('article_p3_2') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[2]">
        <ImanH3>{{ $t('h3_title_4') }}</ImanH3>
        <p>
          {{ $t('article_p4') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[3]">
        <ImanH3>{{ $t('h3_title_5') }}</ImanH3>
        <p>
          {{ $t('article_p5_1') }}
        </p>
        <p class="has-text-primary">
          {{ $t('article_p5_2') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[4]">
        <p>
          {{ $t('article_p6_1') }}
        </p>
        <ul>
          <li>{{ $t('article_p6_2_li_1') }}</li>
          <li>{{ $t('article_p6_2_li_2') }}</li>
          <li>{{ $t('article_p6_2_li_3') }}</li>
        </ul>
        <p>
          {{ $t('article_p6_3') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_3') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard :title="$t('collapse_title_1')">
          <p>
            {{ $t('collapse_p1') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_2')">
            {{ $t('more_info_2') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_2')">
          <p>
            {{ $t('collapse_p2') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_3')">
            {{ $t('more_info_3') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
      </div>
    </section>
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'TraditionalImplantRisk',
    components: {
      ImanMoreInfo
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'dental-implant/trad-implant/risk/rejet-implant-dentaire.jpg',
            position: 'right',
            alt: 'Péri implantite, rejet de l\'implant dentaire définition et causes'
          },
          {
            path: 'dental-implant/trad-implant/risk/soins-periimplantite.jpg',
            position: 'right',
            alt: 'soigner une péri implantite ou infection de l\'implant dentaire'
          },
          {
            path: 'dental-implant/trad-implant/risk/endommagement-nerf-dentaire-inferieur.jpg',
            position: 'right',
            alt: 'risque de section et d\'endommagement du nerf pendant la pose d\'un implant dentaire'
          },
          {
            path: 'dental-implant/trad-implant/risk/implant-dentaire-sinus-perforation.jpg',
            position: 'right',
            alt: 'Perforation du sinus par un implant dentaire'
          },
          {
            path: 'dental-implant/trad-implant/risk/retrait-implant-basal.jpg',
            position: 'right',
            alt: 'Procédure de retrait et d\'extraction d\'un implant dentaire'
          }
        ]
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../../assets/img/thumbnail/MINIATURE_2.1.2.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../../assets/img/dental-implant/trad-implant/risk/h1-risques-rejet-implant-dentaire-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../../assets/img/dental-implant/trad-implant/risk/h1-risques-rejet-implant-dentaire-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../../assets/img/dental-implant/trad-implant/risk/h1-risques-rejet-implant-dentaire-m.jpg");
    }
  }
</style>
